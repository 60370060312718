.l-content_materials {
  display: flex;
  justify-content: center;
  width: 100%;

  & > * {
    flex: 1 1 auto;
    color: $text;
  }
}

.c-materials {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: $max-width;
  padding: 2.75rem 3.125rem 2.125rem 3.125rem;

  @include breakpoint(tablet-landscape) {
    flex-direction: row;
    padding: 5.5rem 6.25rem 3.25rem 6.25rem;
  }
}

.c-materials_title {
  width: 100%;
  margin-bottom: 2rem;

  @include breakpoint(tablet-portrait) {
    margin-bottom: 3rem;
  }
}

.c-materials_list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.c-materials_list_item {
  width: 100%;

  @include breakpoint(tablet-portrait) {
    width: calc((100% / 2) - #{$gutter} / 2);
    margin-right: $gutter;
    margin-bottom: 2rem;

    &:nth-child(2n+0) {
      margin-right: 0;
    }
  }
}
