.l-content_intro {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $primary;

  & > * {
    flex: 1 1 auto;
    color: $negative;
  }
}

.c-intro {
  display: flex;
  flex-wrap: wrap;
  max-width: $max-width;
  padding: 2.75rem 3.125rem 3.125rem 3.125rem;

  @include breakpoint(tablet-landscape) {
    padding: 5.5rem 6.25rem 6.25rem 6.25rem;
  }
}

.c-intro_title {
  padding: 0;
  margin: 0;

  @include breakpoint(tablet-landscape) {
    max-width: calc((100% / 12 * 8) - #{$gutter});
  }
}

.c-intro_link {
  text-decoration: none;
  color: $secondary;
  transition: color 0.2s;

  &:hover {
    color: darken($secondary, 5%);
  }
}
