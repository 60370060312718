.l-content_videos {
  display: flex;
  justify-content: center;
  width: 100%;

  & > * {
    flex: 1 1 auto;
    color: $text;
  }
}

.c-videos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: $max-width;
  padding: 2.75rem 3.125rem 3.125rem 3.125rem;

  @include breakpoint(tablet-landscape) {
    padding: 5.5rem 6.25rem 6.25rem 6.25rem;
  }
}

.c-videos_media {
  width: calc((100% / 2) - #{$gutter / 2});
  margin-right: $gutter;
  margin-bottom: 2rem;

  &:nth-child(2n+0) {
    margin-right: 0;
  }

  @include breakpoint(tablet-portrait) {
    width: calc((100% / 12 * 3) - #{$gutter * 3 / 4});
    margin-bottom: 4rem;

    &:nth-child(2n+0) {
      margin-right: $gutter;
    }

    &:nth-child(4n+0) {
      margin-right: 0;
    }
  }
}
