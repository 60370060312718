.c-article {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
}

.c-article_icon {
  width: 7px;
  height: 9px;
  margin-top: 0.4rem;
  margin-right: 1em;
  fill: $text;

  @include breakpoint(tablet-landscape) {
    width: 10px;
    height: 13px;
  }
}

.c-article_title {
  width: calc(100% - (7px + 1em));
  max-width: calc(100% / 4 * 3);
  text-decoration: none;
  color: $text;
  font-weight: 700;
  transition: color 0.2s;

  &::after {
    content: "";
    display: block;
    width: 5rem;
    height: 1px;
    margin: 1em 50% 0 0;
    background-color: $text;
  }

  &:hover {
    color: $primary;
  }

  @include breakpoint(tablet-landscape) {
    width: calc(100% - (10px + 1em));
  }
}

.c-article_author {
  font-weight: 400;
  color: lighten($text, 30%);
}
