.l-content_related {
  display: flex;
  justify-content: center;
  width: 100%;

  & > * {
    flex: 1 1 auto;
    color: $text;
  }
}

.c-related {
  display: flex;
  flex-wrap: wrap;
  max-width: $max-width;
  padding: 2.75rem 3.125rem 3.125rem 3.125rem;

  @include breakpoint(tablet-landscape) {
    padding: 5.5rem 6.25rem 6.25rem 6.25rem;
  }
}

.c-related_title {
  width: 100%;
  margin-bottom: 2rem;

  @include breakpoint(tablet-portrait) {
    margin-bottom: 3rem;
  }
}

.c-related_media {
  width: calc((100% / 2) - #{$gutter} / 2);
  margin-bottom: 2rem;
  margin-right: $gutter;

  &:nth-child(2n+1) {
    margin-right: 0;
  }

  @include breakpoint(tablet-portrait) {
    width: calc((100% / 4) - #{$gutter} * 3 / 4);
    margin-bottom: 0;

    &:nth-child(2n+1) {
      margin-right: $gutter;
    }

    &:nth-child(4n+1) {
      margin-right: 0;
    }
  }
}
