.l-main_header {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $primary;

  & > * {
    flex: 1 1 auto;
  }
}

.c-header {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: $max-width;
  padding: 2.75rem 3.125rem;

  @include breakpoint(tablet-landscape) {
    padding: 5.5rem 6.25rem;
  }
}

.c-header_logo {
  width: 138px;
  height: 26px;
  margin-left: -2px;
  margin-bottom: 1.5rem;

  svg {
    width: 100%;
    height: 100%;
  }

  @include breakpoint(tablet-portrait) {
    width: 159px;
    height: 30px;
    margin-bottom: 0;
  }
}

.c-header_navigation {
  margin: 0;
  padding: 0;
  list-style-type: none;

  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  @include breakpoint(phablet) {
    margin-top: -1.75em;
  }

  @include breakpoint(tablet-portrait) {
    margin-top: -0.5em;
  }
}

.c-header_navigation_item {
  display: inline;
  margin-left: 1em;

  &:first-child {
    margin-left: 0;
  }

  a {
    text-decoration: none;
    color: $negative;
  }

  @include breakpoint(tablet-portrait) {
    margin-left: 2.5em;
  }
}
