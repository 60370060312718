.l-content_video {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $primary;

  & > * {
    flex: 1 1 auto;
    color: $negative;
  }
}

.c-video {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: $max-width;
  padding: 2.75rem 3.125rem 3.125rem 3.125rem;

  @include breakpoint(tablet-landscape) {
    flex-direction: row;
    padding: 5.5rem 6.25rem 6.25rem 6.25rem;
  }
}

.c-video_info {
  width: 100%;
  max-width: 45em;
  position: relative;
  order: 2;

  @include breakpoint(tablet-landscape) {
    width: calc((100% / 12 * 4) - #{$gutter} / 2);
    order: 1;
  }
}

.c-video_info_subjects {
  @extend h4;
  margin-bottom: 2em;
  list-style-type: none;
}

.c-video_info_subjects_item {
  display: inline;

  &::after {
    content: "\A0/";
  }

  &:last-child::after {
    content: "";
  }
}

.c-video_info_level {
  margin-bottom: 0;
  line-height: 1;

  @include breakpoint(tablet-landscape) {
    position: absolute;
    bottom: 0;
  }
}

.c-video_info_desc {
  &::after {
    content: "";
    display: block;
    width: 5rem;
    height: 1px;
    margin: 2.5em 0;
    background-color: $negative;
  }
}

.c-video_media {
  position: relative;
  width: 100%;
  margin-bottom: 2.5em;
  order: 1;
  cursor: pointer;

  @include breakpoint(tablet-landscape) {
    width: calc((100% / 12 * 8) - #{$gutter} / 2);
    margin-bottom: 0;
    order: 2;
  }
}

.c-video_media_button {
  width: 80px;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.2s ease-in-out;
  overflow: visible;
  pointer-events: none;

  &.is-hidden {
    opacity: 0;
  }

  @include breakpoint(desktop) {
    width: 159px;
    height: 140px;
  }
}

.c-video_media:hover .c-video_media_button {
  transform: translate3d(-50%, -50%, 0) scale(1.08);
}

.c-video_media_poster {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  transition: opacity 0.2s ease-in-out;

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}
