@import "normalize";
@import "variables";
// @import "grid";
@import "header";
@import "single";
@import "single_media";
@import "single_materials";
@import "single_related";
@import "main_intro";
@import "main_filter";
@import "main_videos";
@import "media";
@import "about";
@import "articles";
@import "article";
@import "footer";
@import "modal_email";

* {
  box-sizing: border-box;
}

body.is-locked {
  overflow: hidden;
}
