.l-content_about {
  display: flex;
  justify-content: center;
  width: 100%;

  & > * {
    flex: 1 1 auto;
    color: $text;
  }
}

.c-about {
  display: flex;
  flex-wrap: wrap;
  max-width: $max-width;
  padding: 2.75rem 3.125rem 1.125rem 3.125rem;

  @include breakpoint(tablet-landscape) {
    padding: 5.5rem 6.25rem 3.25rem 6.25rem;
  }
}

.c-about_title {
  width: 100%;
  margin: 0 0 3rem 0;
  text-transform: uppercase;
}

.c-about_text {
  margin: 0 0 3rem 0;

  @include breakpoint(tablet-portrait) {
    margin-bottom: 4rem;
    max-width: calc((100% / 12 * 5) - #{$gutter} / 2);
  }
}

.c-about_text.m-large {
  @extend h1;
  margin-bottom: 2em;
  font-weight: 400;
  line-height: 1.5;

  @include breakpoint(tablet-portrait) {
    max-width: calc((100% / 12 * 8) - #{$gutter} / 2);
  }
}
