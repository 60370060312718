.c-media a {
  color: $text;
  text-decoration: none;
}

.c-media_title {
  transition: color 0.2s;
}

.c-media:hover .c-media_title {
  color: $primary;
}

.c-media_poster {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 1em;
  transition: transform 0.1s ease-in-out;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top:-2%; right: -2%;
    width: 104%;
    height: 104%;
    background-size: contain;
  }
}

.c-media:nth-child(1) .c-media_poster::after,
.c-media:nth-child(8n+1) .c-media_poster::after,
.c-media:nth-child(8n) .c-media_poster::after {
  background-image: url('../assets/icon-overlay-01.svg');
}

.c-media:nth-child(2) .c-media_poster::after,
.c-media:nth-child(8n+2) .c-media_poster::after,
.c-media:nth-child(7) .c-media_poster::after,
.c-media:nth-child(8n+7) .c-media_poster::after {
  background-image: url('../assets/icon-overlay-02.svg');
}

.c-media:nth-child(3) .c-media_poster::after,
.c-media:nth-child(8n+3) .c-media_poster::after,
.c-media:nth-child(6) .c-media_poster::after,
.c-media:nth-child(8n+6) .c-media_poster::after {
  background-image: url('../assets/icon-overlay-03.svg');
}

.c-media:nth-child(4) .c-media_poster::after,
.c-media:nth-child(8n+4) .c-media_poster::after,
.c-media:nth-child(5) .c-media_poster::after,
.c-media:nth-child(8n+5) .c-media_poster::after {
  background-image: url('../assets/icon-overlay-04.svg');
}

.c-media_poster_img {
  display: block;
  width: 100%;
  height: auto;
}

.c-media:hover .c-media_poster {
  transform-origin: center center;
  transform: translateZ(0) scale(1.04);
}

.c-media_subjects {
  @extend h4;
  font-size: 0.875rem;
  list-style-type: none;
}

.c-media_subjects_item {
  display: inline;

  &::after {
    content: "\A0/"
  }

  &:last-child::after {
    content: "";
  }
}
