.l-email {
  opacity: 0;
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0; right: 0; bottom: 0; left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  pointer-events: none;

  &.is-visible {
    display: flex;
    opacity: 1;
    pointer-events: all;
  }
}

.c-email {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: calc(100% - 2 * #{$gutter});
  padding: 1.5625rem 3.125rem;
  background-color: $negative;

  @include breakpoint(tablet-portrait) {
    max-width: calc(100% / 12 * 8);
    padding: 3.125rem 6.25rem;
  }

  @include breakpoint(desktop) {
    max-width: calc(100% / 12 * 6);
  }
}

.c-email_form {
  width: 100%;
}

.c-email_form_title {
  margin-bottom: 2em;
}

.c-email_form_input {
  width: 100%;
  height: 2.625rem;
  padding: 0.5rem;
  border: 1px solid lighten($text, 75%);

  &:focus {
    outline: none;
  }
}

.c-email_form_button {
  width: 100%;
  height: 2.625em;
  margin-bottom: 2em;
  padding: 0.5rem;
  border: none;
  background-color: $primary;
  color: $negative;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: darken($primary, 5%);
  }
}

.c-email_form_disclaimer {
  font-size: 0.825rem;
  color: lighten($text, 25%);
}

.c-email_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;
  }

  svg > * {
    fill: $primary;
  }
}

.c-email_success,
.c-email_error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  text-align: center;
  line-height: 1;
  pointer-events: none;
  transition: opacity 0.3s;
}

.is-loading .c-email_form {
  opacity: 0;
}

.is-loading .c-email_loader {
  opacity: 1;
}

.is-succesful .c-email_loader,
.is-failed .c-email_loader,
.is-succesful .c-email_form,
.is-failed .c-email_form {
  opacity: 0;
}

.is-succesful .c-email_success {
  opacity: 1;
}

.is-failed .c-email_error {
  opacity: 1;
}
