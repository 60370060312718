.l-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid lighten($text, 75%);

  & > * {
    flex: 1 1 auto;
    color: $text;
  }
}

.c-footer {
  display: flex;
  flex-wrap: wrap;
  max-width: $max-width;
  padding: 2.75rem 3.125rem 3.125rem 3.125rem;

  @include breakpoint(tablet-landscape) {
    padding: 5.5rem 6.25rem 6.25rem 6.25rem;
  }
}

.l-footer_block + .l-footer_block {
  @include breakpoint(tablet-portrait) {
    margin-left: calc((100% / 12) + #{$gutter});
  }
}

.c-footer_info {
  width: 100%;
  margin-bottom: 3rem;

  @include breakpoint(tablet-portrait) {
    width: calc((100% / 12 * 5) - #{$gutter} / 2);
    margin-bottom: 0;
  }
}

.c-footer_info_desc {
  width: 100%;
  margin: 0 0 2em 0;
  line-height: 1.5;
}

.c-footer_info_links {
  display: flex;
}

.c-footer_info_link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% / 2) - #{$gutter} / 2);
  height: 2.625em;
  background-color: $primary;
  color: $negative;
  text-decoration: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: darken($primary, 5%);
  }

  @include breakpoint(tablet-portrait) {
    width: calc((100% / 5 * 2) - #{$gutter} / 2);
    font-size: 0.875rem;
  }
}

.c-footer_info_link + .c-footer_info_link {
  margin-left: $gutter;
}

.c-footer_contact {
  width: 100%;
  margin-bottom: 3rem;

  @include breakpoint(tablet-portrait) {
    width: calc((100% / 12 * 2) - #{$gutter});
    margin-bottom: 0;
  }
}

.c-footer_contact_link {
  font-weight: 700;
  margin: 0 0 1em 0;

  a {
    font-weight: 400;
    color: darken($primary, 5%);
    text-decoration: none;
    transition: color 0.2s;
  }

  a:hover {
    color: darken($primary, 15%);
  }

  @include breakpoint(tablet-portrait) {
    font-size: 0.875rem;
  }
}

.c-footer_sponsors {
  width: 100%;

  svg {
    width: 100%;
  }

  svg path {
    fill: $text;
  }

  @include breakpoint(phablet) {
    max-width: 50%;
  }

  @include breakpoint(tablet-portrait) {
    width: calc((100% / 12 * 3) - #{$gutter});
  }
}
