/*
* Colors
*/

$primary: #3CB913;
$secondary: #006831;
$text: #141414;
$negative: #ffffff;

/*
* Media Queries
*/

@mixin breakpoint($size) {
  @if $size == desktop-large {
    @media screen and (min-width: 1920px) { @content ; }
  }

  @if $size == desktop {
    @media screen and (min-width: 1280px) { @content ; }
  }

  @if $size == tablet-landscape {
    @media screen and (min-width: 1024px) { @content ; }
  }

  @if $size == tablet-portrait {
    @media screen and (min-width: 768px) { @content ; }
  }

  @if $size == phablet {
    @media screen and (min-width: 420px) { @content ; }
  }
}

/*
* Layout
*/

$max-width: 1465px;
$gutter: 1.5625rem; // 25px

/*
* Typography
*/

@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700');
@import url('https://rsms.me/inter/inter-ui.css');

html,
body {
  font-family: "Inter UI", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include breakpoint(tablet-landscape) {
    font-size: 14px;
  }

  @include breakpoint(desktop) {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 0.666667em 0;
  padding: 0;
}

h1 {
  font-size: 1.5rem;
  // text-transform: uppercase;
  letter-spacing: 0.025em;
  line-height: 1.333333em;

  @include breakpoint(tablet-portrait) {
    font-size: 1.875rem;
  }
}

h2 {
  @extend h1;
  font-size: 1.25rem;
  font-weight: 400;
}

h3 {
  @extend h1;
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
}

h5 {
  font-size: 1rem;
  font-weight: 400;

  @include breakpoint(desktop) {
    font-size: 0.875rem;
  }
}

p {
  font-family: "Inter UI", sans-serif;
  font-weight: 400;
}

