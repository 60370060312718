.l-content_filter {
  display: flex;
  justify-content: center;
  width: 100%;

  & > * {
    flex: 1 1 auto;
    color: $text;
  }
}

.c-filter {
  display: flex;
  flex-wrap: wrap;
  max-width: $max-width;
  padding: 2.75rem 3.125rem 3.125rem 3.125rem;

  @include breakpoint(tablet-landscape) {
    padding: 2.75rem 6.25rem 3.125rem 6.25rem;
  }
}

.c-filter_title {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.025em;
}

.c-filter_list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: "Rubik", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.c-filter_list_item {
  display: inline;

  &::after {
    content: "\A0/\A0";
  }

  &:last-child::after {
    content: "";
  }

  .is-active {
    font-weight: 700;
    color: $primary;
  }

  a {
    color: $text;
    text-decoration: none;
    transition: color 0.2s;
  }

  a:hover {
    color: $primary;
  }
}
